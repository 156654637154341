import React, { useRef, useEffect } from "react";

const FormField = ({
  type,
  value,
  onChange,
  error,
  label,
  formGroupBaseClass,
  name,
  formAddOnProps
}) => {
  const groupRef = useRef(null);

  useEffect(() => {
    if (value && groupRef.current) {
      groupRef.current.className = `${formGroupBaseClass} active`;
    }
  }, []);

  return (
    <div className="w-full mt-[10px] whitespace-nowrap">
      <div
        className="relative z-0 inline-block mb-6 text-[18px] group text-left w-[386px] max-w-full form-group"
        ref={groupRef}
      >
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e, groupRef)}
          name={name}
          id={`miracl-${name.toLowerCase().replaceAll(" ","-")}`}
          className={"custom-input select-none focus-within:select-text"}
          placeholder=""
          required
          autoCapitalize="off"
          onFocus={() => {
            if (groupRef?.current) {
              groupRef.current.className = `${formGroupBaseClass} active ${
                error && "error"
              }`;
            }
          }}
          onBlur={(e) => {
            if (!e.target.value) {
              groupRef.current.className = `${formGroupBaseClass} ${
                error && "error"
              }`;
            }
          }}
        />
        <label htmlFor={name} className="custom-label select-none">
          {label}
          <span className="text-[#be360c]"> *</span>
        </label>
        {error && (
          <p className="mt-1 text-xs leading-5 pl-5 text-[#be360c] whitespace-normal">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default FormField;
